


















































































import { Component, Vue } from 'vue-property-decorator';
import ModalConfirmacao from '@/components/ModalConfirmacao.vue';
import { readRequisicoesMapa } from '@/store/requisicao_mapa/getters';
import { dispatchGetRequisicoesMapa, dispatchGetRequisicoesMapaFiltradas } from '@/store/requisicao_mapa/actions';
import ModalGerarMapa from '@/components/ModalGerarMapa.vue';
import { IRequisicaoMapa, IRequisicaoMapaSelectFilter } from '@/interfaces/requisicao_mapa';

@Component({
  components: {
    ModalConfirmacao,
    ModalGerarMapa,
  },
})
export default class GerarMapas extends Vue {
    public modalGerarMapaAtivo: boolean = false;
    public show: boolean = false;
    public filtroTipoMapa: string = '';
    public filtroUsuario: string = '';
    public filtroStatus: string = '';
    public headerOrdenado;
    public isTableLoading = true;
    public loadingText = 'Carregando dados...';
    public filtroAtivo = false;
    public filtros: IRequisicaoMapaSelectFilter = {
          tipo_mapa: this.filtroTipoMapa,
          usuario: this.filtroUsuario,
          status: this.filtroStatus,
          ordenacao: false,
          sentido: '',
          campoOrdenar: '',
          skip: 0,
    };

    public headers = [
      {
        text: 'Tipo do mapa',
        sortable: true,
        value: 'tipo_mapa',
        align: 'center',
        ordenacao: '',
        chaveFiltro: 'tipo_mapa',
      },
      {
        text: 'Usuário',
        sortable: true,
        value: 'usuario',
        align: 'center',
        ordenacao: '',
        chaveFiltro: 'usuario',
      },
      {
        text: 'Data/hora',
        sortable: true,
        value: 'data_hora',
        align: 'center',
        ordenacao: '',
        chaveFiltro: 'data_hora',
      },
      {
        text: 'Status',
        sortable: true,
        value: 'status',
        align: 'center',
        ordenacao: '',
        chaveFiltro: 'status',
      },
    ];

    public options = {
      rowsPerPage: 10,
    };

    public async updatePagination(pagination) {
      const totalRegistros = this.requisicoesMapa.length;
      const itensPorPagina = pagination.rowsPerPage;
      const pagina = pagination.page;

      let paginasCache;
      if (totalRegistros > 0) {
        paginasCache = totalRegistros / itensPorPagina;
        if (paginasCache === pagina) {
          if (this.filtroAtivo) {
            this.filtros.skip = totalRegistros;
            await dispatchGetRequisicoesMapaFiltradas(this.$store, this.filtros);
          } else {
            await dispatchGetRequisicoesMapa(this.$store, totalRegistros);
          }
        }
      }
    }

    public fazerDownloadPDF(requisicaoMapa: IRequisicaoMapa) {
      const link = document.createElement('a');
      link.href = requisicaoMapa.arn_s3;
      link.click();
    }

    public ordenarListaPontosAcesso(header) {
      if (this.headerOrdenado && this.headerOrdenado !== header) {
        this.headerOrdenado.ordenacao = '';
      }

      if (header.ordenacao === 'arrow_upward') {
        header.ordenacao = 'arrow_downward';
      } else {
        header.ordenacao = 'arrow_upward';
      }
      this.headerOrdenado = header;
      this.aplicarFiltros(true, header.ordenacao, header.chaveFiltro);
    }

    public async aplicarFiltros(aplicarOrdenacao, sentido, campoOrdenar) {
      if (this.headerOrdenado && campoOrdenar === '') {
        this.headerOrdenado.ordenacao = '';
      }

      this.filtros = {
          tipo_mapa: this.filtroTipoMapa,
          usuario: this.filtroUsuario,
          status: this.filtroStatus,
          ordenacao: aplicarOrdenacao,
          sentido,
          campoOrdenar,
          skip: 0,
        };

      await dispatchGetRequisicoesMapaFiltradas(this.$store, this.filtros);
      this.show = false;
      this.isTableLoading = false;
      this.loadingText = 'Não foram encontrados registros.';
    }

    public async limparFiltros() {
      this.filtroTipoMapa = '';
      this.filtroUsuario = '';
      this.filtroStatus = '';

      this.aplicarFiltros(false, '', '');
      this.show = false;
    }

    get requisicoesMapa() {
      return readRequisicoesMapa(this.$store);
    }

    public abrirModalGerarMapa() {
      this.modalGerarMapaAtivo = true;
    }

    public async mounted() {
      await this.aplicarFiltros(false, '', '');
    }
}
