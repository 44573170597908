


































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { dispatchCreateRequisicaoMapa } from '@/store/requisicao_mapa/actions';
import { dispatchGetMapaLocalidadesPorOrigem } from '@/store/localidade/actions';
import { readMapaLocalidadesPorOrigem } from '@/store/localidade/getters';
import { dispatchGetMapaPontosAcessoPorResponsavel } from '@/store/ponto_acesso/actions';
import { readMapaPontosAcessoPorResponsavel } from '@/store/ponto_acesso/getters';
import { readTiposMapa } from '@/store/tipo_mapa/getters';
import { dispatchGetTiposMapa } from '@/store/tipo_mapa/actions';
import { dispatchGetRequisicoesMapa } from '@/store/requisicao_mapa/actions';
import { IRequisicaoMapaCreate } from '@/interfaces/requisicao_mapa';

@Component
export default class Modal extends Vue {
    public exibir: boolean = true;

    public mapaSelecionado: string = 'Localidade por Origem';
    public loadingGerarMapa: boolean = false;
    public pdf: Blob = new Blob();
    public requisicaoMapa: IRequisicaoMapaCreate = {
        id_tipo_mapa: 0,
        id_status_solicitacao_mapa: 0,
    };

    public async gerarMapa() {
        switch (this.mapaSelecionado) {
            case 'Localidade por Origem':
                this.loadingGerarMapa = true;
                this.requisicaoMapa.id_tipo_mapa = 1;
                this.requisicaoMapa.id_status_solicitacao_mapa = 1;
                await dispatchCreateRequisicaoMapa(this.$store, this.requisicaoMapa);
                break;
            case 'Ponto de acesso por responsavel':
                this.loadingGerarMapa = true;
                await dispatchGetMapaPontosAcessoPorResponsavel(this.$store);
                this.pdf = await readMapaPontosAcessoPorResponsavel(this.$store);
                break;
        }
        this.fecharModal();
        await dispatchGetRequisicoesMapa(this.$store, 0);
    }

    get tiposMapa() {
      return readTiposMapa(this.$store);
    }

    public async mounted() {
      await dispatchGetTiposMapa(this.$store);
    }

    @Watch('exibir')
    public onExibirChanged(val: boolean, oldVal: boolean) {
        if (!val) {
        this.fecharModal();
        }
    }

    @Emit('modalClose')
    public fecharModal() {
        return;
    }

    @Emit('modalConfirm')
    public confirmar() {
        return;
    }
}
